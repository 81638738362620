<template>
<!-- 选中命令后，获取执行该命令的参数储存在store中 -->
  <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-item v-for="v, p in formAttr.params" :label="p" :key="p">
      <a-select v-model:value="formState.allAttr[p]" :placeholder="p">
        <a-select-option v-for="pv in v" :value="pv" :key="pv">{{pv}}</a-select-option>
      </a-select>
    </a-form-item>
    <span v-for="fv, f in formAttr.input_fields" :key="f">
    <a-form-item v-if="fv.input_type === 'DatePicker'" :label="f">
      <a-date-picker
        v-model:value="formState.allAttr[f]"
        type="date"
        :placeholder="f"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item  v-if="fv.input_type === 'InputNumber'" :label="f">
      <a-input-number :id="f" v-model:value="formState.allAttr[f]" />
    </a-form-item>
    </span>
    <a-form-item v-if="ifOption" :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click="onConfirmAttr">确认参数</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
// 获取url参数，用于提交执行
import { defineComponent, reactive, toRaw, watch, ref } from 'vue';
import {message} from 'ant-design-vue';
import {request} from '@/utils/request.js';
import {useStore} from 'vuex';
// import moment, {Moment} from 'moment';
export default defineComponent({
  setup() {
    const ifOption=ref(false)
    const formAttr = reactive({
      params: {},
      input_fields: {}
    });
    const formState = reactive({
        method: '',
        response: '',
        getType: '',
        param_order: [],
        allAttr: {}
    });
    const store = useStore()
    const getOptions = () => {
      request({
        // 获取执行命令的options参数
        url: store.state.data_alys_cmds.beforeExecData['cmdUrls'][store.state.data_alys_cmds.beforeExecData['SelectedCMD']],
        method: "options",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }).then((response) => {
        ifOption.value = true
        try {
          for (var p in response.data['params']) {
            formState.allAttr[p] = undefined
          }
          formAttr.params = response.data['params']
        } catch(e) {
          console.log(e)
        }
        try {
          for (var f in response.data['input_fields']) {
            formState.allAttr[f] = undefined
          }
          formAttr.input_fields = response.data['input_fields']
        } catch(e) {
          console.log(e)
        }
        try {
          formState.method = response.data['method']
        } catch(e) {
          console.log(e)
        }
         try {
          formState.response = response.data['response']
        } catch(e) {
          console.log(e)
        }
        try {
          formState.getType = response.data['getType']
        } catch(e) {
          console.log(e)
        }
        try {
          formState.param_order = response.data['param_order']
        } catch(e) {
          console.log(e)
        }
       
      }).catch(() => {
        message.error('命令执行参数获取失败！')
      }
      );
    }
    watch(() => store.state.data_alys_cmds.beforeExecData, function() {
      store.commit(
          {
            type: "data_alys_cmds/saveExecResultData",
            payload: {'status': ''}
          }
        )
      store.commit(
          {
            type: "data_alys_cmds/saveExecAttrData",
            payload: {'method': ''}
          }
        )
      if (store.state.data_alys_cmds.beforeExecData['SelectedCMD'] && store.state.data_alys_cmds.beforeExecData['cmdUrls'][store.state.data_alys_cmds.beforeExecData['SelectedCMD']]) {
        getOptions()
      }
    })
    const onConfirmAttr = () => {
      const datePatt = new RegExp('date')
      for (var dt in formState.allAttr) {
        if (datePatt.test(dt)) {
          // 我也不懂，有时候浏览器提供字符串，有时候提供moment对象。
          try {
            formState.allAttr[dt]=formState.allAttr[dt].split('T')[0]
          } catch(e) {
            console.log(e)
          }
          try {
            formState.allAttr[dt]=formState.allAttr[dt].format('YYYY-MM-DD')
          } catch(e) {
            console.log(e)
          }
        }
      }
      store.commit(
          {
          type: "data_alys_cmds/saveExecAttrData",
        payload: toRaw(formState)
        }
        )
      message.success('参数确认成功！')
    };

    return {
      ifOption,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      formState,
      formAttr,
      onConfirmAttr,
    };
  },
});
</script>