<template>
  <a-select label-in-value v-model:value="SelectedCMD" style="width: 300px" @change="handleChange">
    <a-select-option v-for="k in CMDs" :value="k" :key="k">{{ k }}</a-select-option>
  </a-select>
</template>
<script>
// 获取一个路由中子路由信息，作为需要申请的url命令
import { defineComponent, onMounted, getCurrentInstance, ref } from 'vue';
import {message} from 'ant-design-vue'
import {request} from '@/utils/request.js'
import {useStore} from 'vuex'
export default defineComponent({
  props: ['CMDIndexUrl', 'routeSelectedCmd'],
  setup(props) {
    const store = useStore()
    // 获取全局属性的方法
    const properties = getCurrentInstance()
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    // 获取首页的链接信息
    const CMDUrls = ref({'请选择下列选项': ''})
    const CMDs = ref(['请选择下列选项'])
    const SelectedCMD = ref('请选择下列选项')
    onMounted(() => {
      request({
        // 获取全局属性的方法
        url: baseUrl + props.CMDIndexUrl,
        method: "get",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }).then((response) => {
        var pt = new RegExp("<")
        for (var k in response.data) {
          if (!pt.test(k)) {
            CMDUrls.value[k] = baseUrl + props.CMDIndexUrl + k + '/'
            CMDs.value.push(k)
          }
        }
        store.commit(
        {
          type: "data_alys_cmds/saveData",
        payload: props.routeSelectedCmd ? {'cmdUrls': CMDUrls, 'SelectedCMD': props.routeSelectedCmd} : {'cmdUrls': CMDUrls}
        }
      )
      }).catch(() => {
        message.error('获取数据处理方法列表失败！')
      }
      );
    })
    const handleChange = value => {
      store.commit(
        {
          type: "data_alys_cmds/saveData",
        payload: {'cmdUrls': store.state.data_alys_cmds.beforeExecData['cmdUrls'], 'SelectedCMD': value.value}
        }
      )
    };

    return {
      CMDUrls,
      CMDs,
      handleChange,
      SelectedCMD
    };
  },
});
</script>