<template>
  <div class="clearfix" v-if="$store.state.data_alys_cmds.execAttr['method'] === 'upload'">
    <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
      <a-button>
        <upload-outlined></upload-outlined>
        请选择指定类型的文件
      </a-button>
    </a-upload>
    <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
    >
      {{ uploading ? '正在执行...' : '开始执行' }}
    </a-button>
  </div>
</template>
<script>
// 如果执行的命令有需要上传的文件，使用这个文件
import request from 'umi-request';
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
import {useStore} from 'vuex';
export default defineComponent({
  components: {
    UploadOutlined,
  },

  setup() {
    const fileList = ref([]);
    const uploading = ref(false);
    const store = useStore();
    const fileField = ref('')
    const handleRemove = file => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    const beforeUpload = file => {
      fileList.value = [...fileList.value, file];
      return false;
    };

    const handleUpload = () => {
      const formData = new FormData();
        const fPatt = new RegExp('file')
      for (var f in store.state.data_alys_cmds.execAttr['allAttr']) {
          if (fPatt.test(f)) {
              fileField.value = f
          } else {
              formData.append(f, store.state.data_alys_cmds.execAttr['allAttr'][f])
          }
      }
      fileList.value.forEach(file => {
        formData.append(fileField.value, file);
      });
      uploading.value = true; // You can use any AJAX library you like

      request(store.state.data_alys_cmds.beforeExecData['cmdUrls'][store.state.data_alys_cmds.beforeExecData['SelectedCMD']], {
        method: 'post',
        data: formData,
        headers:  {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        }
      })
        .then((res) => {
          if (store.state.data_alys_cmds.execAttr['response'] === 'json') {
            store.commit(
          {
            type: "data_alys_cmds/saveExecResultData",
            payload: {'status': 'done', 'description': '计算完成，详查结果', 'result': res.result}
          }
        )
          } else if (store.state.data_alys_cmds.execAttr['response'] === 'file') {
            window.location = res.excel_file
          }
          
          fileList.value = [];
          uploading.value = false;
          message.success('执行成功.');
        })
        .catch((e) => {
          uploading.value = false;
          message.error(e+'执行失败.');
        });
    };

    return {
      fileList,
      uploading,
      handleRemove,
      beforeUpload,
      handleUpload,
    };
  },
});
</script>