<template>
  <a-button type="primary" :loading="loading" :enable='!loading'
  @click="onSubmit"
  v-if="$store.state.data_alys_cmds.execAttr['method'] !== 'upload'"
  >
    <span v-if="loading">等待确认参数</span>
    <span v-if="!loading">确认执行</span>
    </a-button>
</template>
<script>
// 请求已经生成的需要请求的url命令，可以处理json返回对象以及文件（file）返回对象。
import { defineComponent, ref, watch } from 'vue';
import {useStore} from 'vuex';
import {message} from 'ant-design-vue';
import {request, fileHandler} from '@/utils/request.js'
// import moment from 'moment';
export default defineComponent({
  setup() {
    const loading = ref(true);
    const store = useStore();
    watch(() => store.state.data_alys_cmds.execAttr, function() {
      if (store.state.data_alys_cmds.execAttr['method']) {
        loading.value=false
      }
    })
    var fetchUrl = store.state.data_alys_cmds.beforeExecData['cmdUrls'][store.state.data_alys_cmds.beforeExecData['SelectedCMD']]
    var NormalData = {}
    const jsonHandler = (Url) => {
      request({
        // 获取全局属性的方法
        url: Url,
        method: "get",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }).then((response) => {
        store.commit(
          {
            type: "data_alys_cmds/saveExecResultData",
            payload: response.data
          }
        )
      }).catch((e) => {
        message.error(e+'执行失败！')
      }
      )
    }
    const fileHandlerOnError = (e) => {store.commit(
          {
            type: "data_alys_cmds/saveExecResultData",
            payload: NormalData
          }
        )
        message.error(e+'执行/下载失败')}
    const onSubmit = () => {
      fetchUrl = store.state.data_alys_cmds.beforeExecData['cmdUrls'][store.state.data_alys_cmds.beforeExecData['SelectedCMD']]
      if (store.state.data_alys_cmds.execAttr['method'] === 'get') {
        fetchUrl = fetchUrl + store.state.data_alys_cmds.execAttr['getType'] + '/'
        for (var po in store.state.data_alys_cmds.execAttr['param_order']) {
          fetchUrl = fetchUrl + store.state.data_alys_cmds.execAttr['allAttr'][store.state.data_alys_cmds.execAttr['param_order'][po]] + '/'
        }
        if (store.state.data_alys_cmds.execAttr['response'] === 'json') {
          jsonHandler(fetchUrl)
        } else if (store.state.data_alys_cmds.execAttr['response'] === 'file') {
          fileHandler(fetchUrl, "JWT " + store.state.user_login_auth.user["token"], fileHandlerOnError)
        }
        
      } else if (store.state.data_alys_cmds.execAttr['method'] === 'post') {
console.log('post')
      }
    }
    return {
      loading,
      onSubmit,
    };
  },
});
</script>